import React from "react";
import Countdown from "./components/Countdown.js";
import "./App.css";
import Snowfall from "react-snowfall";
import Footer from "./components/Footer/Footer.js";

const App = () => {
  return (
    <div className="app">
      <Snowfall />
      <header>
        <h1>🎄 Christmas Countdown 🎅</h1>
      </header>
      <main>
        <Countdown />
      </main>
      <Footer />
      <footer>
        <p>© 2024 ChristmasCountdowns</p>
      </footer>
    </div>
  );
};

export default App;
