import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Countdown.css";

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isChristmas, setIsChristmas] = useState(false);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const currentYear = now.getFullYear();
      let christmas = new Date(`December 25, ${currentYear} 00:00:00`).getTime();

      // If it's already past this year's Christmas, move to the next year
      if (now.getTime() > christmas) {
        christmas = new Date(`December 25, ${currentYear + 1} 00:00:00`).getTime();
      }

      const difference = christmas - now.getTime();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
        setIsChristmas(false); // Not yet Christmas
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        setIsChristmas(true); // It's Christmas!
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, []);

  const variants = {
    initial: { scale: 0.8, opacity: 0.5 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0.8, opacity: 0 },
  };

  return (
    <>
      <div className="countdown">
        {isChristmas ? (
          <div className="christmas-message">
            <h1>Merry Christmas! 🎄🎅</h1>
            <p>Enjoy the holiday with your loved ones!</p>
          </div>
        ) : (
          Object.entries(timeLeft).map(([unit, value]) => (
            <div key={unit} className="countdown-item">
              <AnimatePresence mode="wait">
                <motion.p
                  key={value} // Triggers animation on value change
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={variants}
                  transition={{ duration: 0.3 }}
                >
                  {value}
                </motion.p>
              </AnimatePresence>
              <span>{unit.charAt(0).toUpperCase() + unit.slice(1)}</span>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Countdown;
