import React from "react";
import { ReactComponent as AppStoreIcon } from "../../assets/app-store.svg"; // Importing SVG as a React component
import { ReactComponent as PlayStoreIcon } from "../../assets/play-store.svg"; // Importing SVG as a React component

import "./Footer.css"; // Optional: For styling

function Footer() {
  const handlePlayStoreClick = () => {
    alert("Coming Soon on Google Play!");
  };

  return (
    <footer className="footer">
      <div className="store-icons">
        {/* App Store Link */}
        <a
          href="https://apps.apple.com/us/app/id1544660121" // Replace with your App Store link
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppStoreIcon className="store-icon" width={140} height={40} />
        </a>

        {/* Play Store Link */}
        <a
          // href="https://play.google.com/store/apps/details?id=com.example.app" // Replace with your Play Store link
          href="#"
          onClick={handlePlayStoreClick} // On click show message
          // target="_blank"
          rel="noopener noreferrer"
        >
          <PlayStoreIcon className="store-icon" width={140} height={40} />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
